import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import Card from "../../../components/Card/Card.js";
import { withErrorBoundary } from "../../../hoc.js";
import { shorten } from "../../../utils/address.js";
import {
  barGraphSeriesCountLimiter,
  tooltipLabelNumberWithPercent,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function CollateralBuyersTop10Chart(props) {
  const { data, baseUnderlyingSymbol, timePeriod } = props;

  const { series } = barGraphSeriesCountLimiter(data, "key", "value", 7, false);

  const newSeries = [
    {
      data: series[0]["data"],
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index, ticks) {
            return shorten(this.getLabelForValue(value));
          },
        },
      },
      y: {
        ticks: {
          callback: (value) => compact(value, 2, true) + ` ${baseUnderlyingSymbol}`,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return tooltipLabelNumberWithPercent(
              tooltipItem,
              null,
              ` ${baseUnderlyingSymbol}`
            );
          },
        },
      },
    },
  };

  return (
    <Card title={`Top ten collateral buyers in last ${timePeriod} days`} minHeight>
      <Graph series={newSeries} options={options} type="bar" />
    </Card>
  );
}

export default withErrorBoundary(CollateralBuyersTop10Chart);
