import React from "react";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import Info from "../../../components/Info/Info.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { parseUTCDateTime } from "../../../utils/datetime.js";

function WalletAdditionalInfo(props) {
  let { data, ...rest } = props;
  if (!data) {
    return null;
  }

  const {
    last_activity: lastActivity,
    first_activity: firstActivity,
    liquidation_buffer: liquidationBuffer,
    collateral_ratio: collateralRatio,
    drop,
  } = data || {};

  const additionalStats = [
    {
      title: "drop to liquidation",
      normalValue: (
        <>
          {liquidationBuffer ? (
            <Value value={drop * 100} decimals={0} suffix="%" />
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      title: (
        <>
          Collateral Ratio
          <Info title="Collateral Ratio">
            <p>
              Collateral ratio is a financial term that refers to the ratio of the value
              of the collateral pledged for a loan or investment to the value of the
              loan or investment itself.
            </p>
            <p>
              For example, if someone takes out a $100,000 loan and pledges $125,000
              worth of assets as collateral, then the collateral ratio would be 125%,
              calculated as ($125,000 / $100,000) x 100%.
            </p>
            <p>
              Collateral ratio is used by lenders and investors to assess the risk of a
              loan or investment. A higher collateral ratio indicates that there is more
              security for the lender or investor in case of default, as they have a
              greater claim to the assets pledged as collateral. A lower collateral
              ratio indicates that the lender or investor is taking on more risk, as
              they have a weaker claim to the pledged assets in case of default.
            </p>
          </Info>
        </>
      ),
      normalValue: (
        <>
          {collateralRatio ? (
            <Value value={collateralRatio * 100} decimals={0} suffix="%" />
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      title: "last activity",
      normalValue: <DateTimeAgo dateTime={parseUTCDateTime(lastActivity)} inDays />,
    },
    {
      title: "first activity",
      normalValue: <DateTimeAgo dateTime={parseUTCDateTime(firstActivity)} inDays />,
    },
  ];

  return <StatsBar stats={additionalStats} {...rest} />;
}

export default withErrorBoundary(WalletAdditionalInfo);
