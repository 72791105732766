import React from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import Graph from "../../../components/Graph/Graph.js";
import Card from "../../../components/Card/Card.js";
import { withErrorBoundary } from "../../../hoc.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import { parseUTCDateTime } from "../../../utils/datetime.js";

function CollateralBought(props) {
  const { data, baseUnderlyingSymbol, timePeriod } = props;
  const maxDate = DateTime.now().toMillis();

  const grouped = _.groupBy(data, "underlying_symbol");

  const series = [];
  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["date"],
        y: row["amount_base"],
      })),
    };
    series.push(item);
  });

  // Copy last value in the series as "todays" value if it's missing to make the graph
  // presentable
  series.forEach((serie) => {
    const lastElement = serie.data.at(-1);
    if (parseUTCDateTime(lastElement["x"]) < maxDate) {
      serie.data.push({
        x: maxDate,
        y: lastElement["y"],
      });
    }
  });

  const options = {
    interaction: {
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        min: DateTime.now().minus({ days: timePeriod }).toMillis(),
        max: maxDate,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => compact(value, 2, true) + ` ${baseUnderlyingSymbol}`,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, null, ` ${baseUnderlyingSymbol}`);
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            return `Total: ` + compact(total, 2, true) + ` ${baseUnderlyingSymbol}`;
          },
        },
      },
    },
  };

  return (
    <Card title="Collateral bought" minHeight>
      <Graph series={series} options={options} />
    </Card>
  );
}

export default withErrorBoundary(CollateralBought);
