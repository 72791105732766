import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { SLASH_REGEX } from "../../utils/url.js";
import CryptoIcon from "../CryptoIcon/CryptoIcon.js";
import styles from "./NetworkSelector.module.scss";

function NetworkSelector(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const pathname = location.pathname.replace(SLASH_REGEX, "");
  const paths = pathname.split("/");

  const options = {
    v3: [
      {
        value: "ethereum-usdc",
        protocol: "ethereum",
        base: "usdc",
        text: "USDC",
      },
      {
        value: "ethereum-eth",
        protocol: "ethereum",
        base: "eth",
        text: "ETH",
      },
      {
        value: "polygon-usdc",
        protocol: "polygon",
        base: "usdc",
        text: "USDC",
      },
    ],
  };

  const currentVersion = paths[0];
  const currentNetwork = options[paths[0]].find((network) => {
    if (currentVersion === "v3") {
      return network.value === `${paths[1]}-${paths[2]}`;
    }
    return network.value === paths[1];
  });

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onItemClick = (version, network) => {
    let url = `/${version}/${network.protocol}/${network.base}/`;
    const path = paths.slice(3).join("/");
    if (path.length > 0) {
      url = url + path + "/";
    }

    navigate(url);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret tag="span" className={styles.dropdownToggle}>
          <CryptoIcon
            size="1.2rem"
            className="me-2"
            name={currentNetwork.base.toUpperCase()}
          />
          {currentNetwork.text} <Badge className="ms-2">{currentVersion}</Badge>
        </DropdownToggle>
        <DropdownMenu>
          {/* <DropdownItem header className="text-center">
            ethereum
          </DropdownItem> */}
          {options.v3.map((network) =>
            network.protocol === "ethereum" ? (
              <DropdownItem
                key={`v3-${network.value}`}
                active={
                  currentVersion === "v3" && currentNetwork.value === network.value
                }
                onClick={() => {
                  onItemClick("v3", network);
                }}
              >
                <CryptoIcon
                  size="1.2rem"
                  className="me-2"
                  name={network.base.toUpperCase()}
                />
                {network.text}
              </DropdownItem>
            ) : null
          )}
          {/* <DropdownItem header className="text-center">
            polygon
          </DropdownItem>
          {options.v3.map((network) =>
            network.protocol === "polygon" ? (
              <DropdownItem
                key={`v3-${network.value}`}
                active={
                  currentVersion === "v3" && currentNetwork.value === network.value
                }
                onClick={() => {
                  onItemClick("v3", network);
                }}
              >
                <CryptoIcon
                  size="1.2rem"
                  className="me-2"
                  name={network.base.toUpperCase()}
                />
                {network.text}
              </DropdownItem>
            ) : null
          )} */}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NetworkSelector;
