import classnames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import { withErrorBoundary } from "../../../hoc.js";
import SupplyHistoryChart from "./SupplyHistoryChart.js";
import TotalAtRiskChart from "./TotalAtRiskChart.js";

function CollateralSection(props) {
  const { timePeriod, className, ...rest } = props;

  return (
    <Row className={classnames(className, "justify-content-center")} {...rest}>
      <Col lg={6} className="mb-4">
        <SupplyHistoryChart timePeriod={timePeriod} />
      </Col>
      <Col lg={6} className="mb-4">
        <TotalAtRiskChart timePeriod={timePeriod} />
      </Col>
    </Row>
  );
}

export default withErrorBoundary(CollateralSection);
