import React from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Loader from "../../../components/Loader/Loader.js";
import LinkTable from "../../../components/Table/LinkTable.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch, useSmartNavigate } from "../../../hooks.js";

function MarketsTable(props) {
  const { timePeriod } = props;
  const navigate = useSmartNavigate();
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch("markets/", {
    days_ago: timePeriod,
  });

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results, base_underlying_symbol: baseSymbol } = data;

  const onRowClick = (e, row) => {
    navigate(e, `markets/${row.underlying_symbol}/`);
  };

  return (
    <LinkTable
      keyField="name"
      data={results}
      onRowClick={onRowClick}
      defaultSorted={[
        {
          dataField: "total_supply_base",
          order: "desc",
        },
      ]}
      columns={[
        {
          dataField: "underlying_symbol",
          text: "",
          sort: false,
          formatter: (cell, row) => (
            <CryptoIcon name={row.underlying_symbol} size="2em" id={cell} />
          ),
          classes: "table-icon-cell",
        },
        {
          dataField: "name",
          text: "",
          sort: false,
          formatter: (cell, row) => (
            <>
              {row.underlying_symbol}
              <span className="text-small gray ms-2">
                (
                <Value value={row.underlying_price_usd} decimals={2} prefix="$" />)
              </span>
              <br />
              <span className="text-small gray">{row.name}</span>
            </>
          ),
        },
        {
          dataField: "total_supply_base",
          text: "Total Supply",
          sort: true,
          formatter: (cell, row) => (
            <>
              <Value
                value={row.total_supply}
                decimals={2}
                suffix={row.underlying_symbol}
                compact
              />
              <br />
              <Value
                value={row.total_supply_base}
                decimals={2}
                suffix={baseSymbol}
                compact
                small
                className="gray"
              />
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "",
          text: `${timePeriod}d change`,
          formatter: (cell, row) => (
            <>
              <ValueChange
                value={row.total_supply - row.changes.total_supply}
                decimals={2}
                tooltipValue={row.changes.total_supply}
                suffix={row.underlying_symbol}
                hideIfZero
                compact
              />
              <br />
              <ValueChange
                value={row.total_supply_base - row.changes.total_supply_base}
                decimals={2}
                tooltipValue={row.changes.total_supply_base}
                suffix={baseSymbol}
                hideIfZero
                compact
                className="gray"
                small
              />
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "supply_cap",
          text: "Supply Cap",
          sort: true,
          formatter: (cell, row) => (
            <>
              <Value
                value={row.supply_cap}
                decimals={2}
                suffix={row.underlying_symbol}
                compact
              />
              <br />
              <Value
                value={row.supply_cap_base}
                decimals={2}
                suffix={baseSymbol}
                compact
                className="gray"
                small
              />
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "y",
          text: "utilization",
          formatter: (cell, row) => (
            <>
              <Value
                value={(row.total_supply / row.supply_cap) * 100}
                decimals={2}
                suffix="%"
              />
              <br />
              <ValueChange
                value={
                  (row.total_supply / row.supply_cap) * 100 -
                  (row.changes.total_supply / row.changes.supply_cap) * 100
                }
                decimals={2}
                hideIfZero
                tooltipValue={(row.changes.total_supply / row.changes.supply_cap) * 100}
                suffix="%"
                small
              />
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
        // {
        //   dataField: "borrow_utilization",
        //   text: "Borrow Utilization",
        //   formatter: (cell, row) => (
        //     <Value value={cell * 100} decimals={0} suffix="%" />
        //   ),
        //   headerAlign: "right",
        //   align: "right",
        // },
        {
          dataField: "collateral_factor",
          text: "Coll. factor",
          formatter: (cell, row) => (
            <Value value={cell * 100} decimals={0} suffix="%" />
          ),
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "liquidation_threshold",
          text: "Liq. factor",

          formatter: (cell, row) => (
            <Value value={cell * 100} decimals={0} suffix="%" />
          ),
          headerAlign: "right",
          align: "right",
        },
      ]}
    />
  );
}

export default withErrorBoundary(MarketsTable);
