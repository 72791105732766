import React from "react";
import { Row, Col } from "reactstrap";
import Loader from "../../../components/Loader/Loader.js";
import Title from "../../../components/Title/Title.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import WalletPositionsBase from "./WalletPositionsBase.js";
import WalletPositionsCollateral from "./WalletPositionsCollateral.js";

function WalletPositions(props) {
  const { address } = props;

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `wallets/${address}/positions/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const {
    collateral_positions: collateralPositions,
    base_positions: basePositions,
    base_underlying_symbol: baseUnderlyingSymbol,
  } = data;

  return (
    <>
      <Title tag="h2">Positions</Title>
      <Row className="mb-5">
        <Col md={6}>
          <WalletPositionsCollateral
            data={collateralPositions}
            baseUnderlyingSymbol={baseUnderlyingSymbol}
          />
        </Col>
        <Col md={6}>
          <WalletPositionsBase data={basePositions} />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(WalletPositions);
