import React, { useState } from "react";
import { Route, Routes } from "react-router";
import { Link, Navigate, useLocation } from "react-router-dom";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import logoCompound from "../../images/compound-logo.svg";
import baLogo from "../../images/logo-light.svg";
import ErrorPage from "../../pages/error/ErrorPage.js";
import Homepage from "../../pages/homepage/Homepage.js";
import Wallet from "../../pages/wallets/Wallet.js";
import Wallets from "../../pages/wallets/Wallets.js";
import Market from "../../pages/markets/Market.js";
import Markets from "../../pages/markets/Markets.js";
import Liquidations from "../../pages/liquidations/Liquidations.js";
import CollateralBuyer from "../../pages/liquidations/CollateralBuyer.js";
import Activity from "../../pages/activity/Activity.js";
import { smartLocationPrefix } from "../../utils/url.js";
import NetworkSelector from "../NetworkSelector/NetworkSelector.js";
import styles from "./Layout.module.scss";

function Layout(props) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);

  const location = useLocation();
  const locationPrefix = smartLocationPrefix(location);

  const v3EthereumUSDCRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "wallets/", element: <Wallets /> },
    { path: "wallets/:address/", element: <Wallet /> },
    { path: "markets/", element: <Markets /> },
    { path: "markets/:symbol/", element: <Market /> },
    { path: "liquidations/", element: <Liquidations /> },
    { path: "collateral-buyers/:address/", element: <CollateralBuyer /> },
    { path: "activity/", element: <Activity /> },
  ];

  const v3EthereumETHRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "wallets/", element: <Wallets /> },
    { path: "wallets/:address/", element: <Wallet /> },
    { path: "markets/", element: <Markets /> },
    { path: "markets/:symbol/", element: <Market /> },
    { path: "liquidations/", element: <Liquidations /> },
    { path: "collateral-buyers/:address/", element: <CollateralBuyer /> },
    { path: "activity/", element: <Activity /> },
  ];

  const v3PolygonUSDCRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "wallets/", element: <Wallets /> },
    { path: "wallets/:address/", element: <Wallet /> },
    { path: "markets/", element: <Markets /> },
    { path: "markets/:symbol/", element: <Market /> },
    { path: "liquidations/", element: <Liquidations /> },
    { path: "collateral-buyers/:address/", element: <CollateralBuyer /> },
    { path: "activity/", element: <Activity /> },
  ];

  const prefix = locationPrefix.length > 0 ? locationPrefix : "/";
  return (
    <>
      <Container>
        <header className="mb-4">
          <Navbar expand="md" className="fw-bolder" dark container={false}>
            <NavbarBrand className={styles.navbarBrand} tag={Link} to={`${prefix}`}>
              <img className={styles.logo} src={logoCompound} alt="Compound" />
            </NavbarBrand>
            <Collapse isOpen={isNavbarOpen} navbar>
              <Nav className="flex-grow-1 justify-content-right" navbar>
                <NavItem>
                  <NavLink className={styles.navLink} tag={Link} to={`${prefix}`}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}markets/`}
                  >
                    Markets
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}wallets/`}
                  >
                    Wallets
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}liquidations/`}
                  >
                    Liquidations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}activity/`}
                  >
                    Activity
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
            {locationPrefix.length > 0 ? <NetworkSelector /> : null}
            <NavbarToggler onClick={toggleNavbar} />
          </Navbar>
        </header>

        <main>
          <Routes>
            <Route index element={<Navigate replace to="/v3/ethereum/usdc/" />} />

            {/* V3 Ethereum USDC */}
            {v3EthereumUSDCRoutes.map((route) => {
              const path = `v3/ethereum/usdc/${route.path}`;
              return <Route key={path} path={path} element={route.element} />;
            })}
            {/* V3 Ethereum ETH */}
            {v3EthereumETHRoutes.map((route) => {
              const path = `v3/ethereum/eth/${route.path}`;
              return <Route key={path} path={path} element={route.element} />;
            })}
            {/* V3 Ethereum ETH */}
            {v3PolygonUSDCRoutes.map((route) => {
              const path = `v3/polygon/usdc/${route.path}`;
              return <Route key={path} path={path} element={route.element} />;
            })}
            {/* Catch all */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </main>
      </Container>

      <footer className="mt-4 text-center p-3">
        <div className="d-flex justify-content-center align-items-baseline gray small mb-1">
          <img src={baLogo} alt="blockanalitica" className={styles.footerLogo} />
          &copy;2023
        </div>
      </footer>
    </>
  );
}

export default Layout;
