export const SYMBOLS_PALETTE = {
  WETH: "#627eea",
  ETH: "#627eea",
  USDC: "#3e73c4",
  WBTC: "#ef8e19",
  BTC: "#ef8e19",
  LINK: "#2a5ada",
  Others: "#FFFFFF",
  others: "#FFFFFF",
  UNI: "#ff007a",
  wstETH: "#00a3ff",
  cbETH: "#0052ff",
  COMP: "#00d395",
  USD: "#00d395",
};
