import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import Card from "../../../components/Card/Card.js";
import { withErrorBoundary } from "../../../hoc.js";
import {
  tooltipFooterTotal,
  tooltipLabelNumber,
  tooltipTitleDateTime,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function LiquidationsChart(props) {
  const { data, daysAgo, type, baseUnderlyingSymbol } = props;

  let xUnit = "day";
  if (daysAgo > 180) {
    xUnit = "month";
  } else if (daysAgo > 30) {
    xUnit = "week";
  }

  let amountKey = "debt_amount_usd";
  if (type === "collateral") {
    amountKey = "collateral_amount_usd";
  }

  const series = [];
  Object.entries(data).forEach(([key, rows]) => {
    if (type === "collateral" && key === "null") {
      return;
    } else if (type === "debt" && key !== "null") {
      return;
    }

    const symbol = key === "null" ? baseUnderlyingSymbol : key;
    series.push({
      label: symbol,
      symbol,
      data: rows.map((row) => ({
        x: row["datetime"],
        y: row[amountKey],
      })),
    });
  });

  const options = {
    orderSeries: true,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: xUnit,
          displayFormats: {
            week: "W yyyy",
          },
        },
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            let format = "LLL yyyy";
            let prefix = "";
            if (xUnit === "week") {
              prefix = "Week starting on ";
              format = null;
            }
            return prefix + tooltipTitleDateTime(tooltipItems, true, false, format);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
          footer: (tooltipItems) => {
            if (type === "collateral") {
              return tooltipFooterTotal(tooltipItems, "Total: $");
            }
          },
        },
      },
    },
  };
  return (
    <Card title="Collateral seized" minHeight>
      <Graph series={series} options={options} type="bar" key={type} />
    </Card>
  );
}

export default withErrorBoundary(LiquidationsChart);
