import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { copyTextToClipboard } from "../../utils/clipboard.js";

function CopyToClipboard(props) {
  const { text, className, ...rest } = props;

  const btnRef = useRef(null);
  const [ready, setReady] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const [timer, setTimer] = useState();

  React.useEffect(() => {
    if (btnRef.current) {
      setReady(true);
    }
  }, [btnRef]);

  const btnClick = () => {
    copyTextToClipboard(text);
    setIsCopied(true);

    clearTimeout(timer);
    const tmr = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    setTimer(tmr);
  };

  return (
    <>
      <FontAwesomeIcon
        onClick={btnClick}
        icon={faCopy}
        className={classnames("link", "gray", className)}
        ref={btnRef}
        {...rest}
      />
      {ready ? (
        <UncontrolledTooltip placement="bottom" target={btnRef}>
          {isCopied ? "Address copied!" : "Click to copy"}
        </UncontrolledTooltip>
      ) : null}
    </>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CopyToClipboard.defaultProps = {};

export default CopyToClipboard;
