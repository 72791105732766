import React from "react";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";

function MarketInfo(props) {
  const { data, ...rest } = props;
  if (!data) {
    return null;
  }

  const { change = {} } = data;

  const totalStats = [
    {
      title: "Supply",
      normalValue: (
        <>
          <Value
            value={data.total_supply_base}
            decimals={2}
            suffix={data.base_underlying_symbol}
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.total_supply_base - change.total_supply_base}
            decimals={2}
            tooltipValue={change.total_supply_base}
            compact
            hideIfZero
          />
        </>
      ),
      bigValue: (
        <>
          <Value
            value={data.total_supply}
            decimals={2}
            suffix={data.underlying_symbol}
            compact
            big
            className="me-2"
          />
          <ValueChange
            value={data.total_supply - change.total_supply}
            decimals={2}
            tooltipValue={change.total_supply}
            compact
            hideIfZero
            big
          />
        </>
      ),
    },
    {
      title: "Supply Cap",
      normalValue: (
        <>
          <Value
            value={data.supply_cap_base}
            decimals={2}
            suffix={data.base_underlying_symbol}
            compact
            className="me-2 gray"
          />
          <ValueChange
            value={data.supply_cap_base - change.supply_cap_base}
            decimals={2}
            compact
            hideIfZero
            tooltipValue={change.supply_cap_base}
          />
        </>
      ),
      bigValue: (
        <>
          <Value
            value={data.supply_cap}
            decimals={2}
            suffix={data.underlying_symbol}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={data.supply_cap - change.supply_cap}
            decimals={2}
            tooltipValue={change.supply_cap}
            compact
            hideIfZero
            big
          />
        </>
      ),
    },
    {
      title: "utilization",
      bigValue: (
        <>
          <Value
            value={(data.total_supply / data.supply_cap) * 100}
            decimals={2}
            suffix="%"
            className="me-2"
          />
          <ValueChange
            value={
              (data.total_supply / data.supply_cap) * 100 -
              (change.total_supply / change.supply_cap) * 100
            }
            decimals={2}
            hideIfZero
            tooltipValue={(change.total_supply / change.supply_cap) * 100}
            suffix="%"
          />
        </>
      ),
    },
    {
      title: "oracle price",
      bigValue: (
        <>
          <Value
            value={data.underlying_price}
            decimals={data.underlying_price < 2 ? 4 : 2}
            suffix={data.base_underlying_symbol}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={data.underlying_price - change.underlying_price}
            decimals={data.underlying_price < 2 ? 4 : 2}
            tooltipValue={change.underlying_price}
            compact
            hideIfZero
            big
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.underlying_price_usd}
            decimals={2}
            prefix="$"
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.underlying_price_usd - change.underlying_price_usd}
            decimals={2}
            prefix="$"
            tooltipValue={change.underlying_price_usd}
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  return <StatsBar stats={totalStats} {...rest} />;
}

export default withErrorBoundary(MarketInfo);
