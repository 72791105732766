import classnames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import { withErrorBoundary } from "../../../hoc.js";
import SupplyBorrowAPRChart from "./SupplyBorrowAPRChart.js";
import TotalSupplyBorrowChart from "./TotalSupplyBorrowChart.js";

function MarketsSection(props) {
  const { timePeriod, className, ...rest } = props;

  return (
    <Row className={classnames(className, "justify-content-center")} {...rest}>
      <Col md={6} className="mb-4">
        <TotalSupplyBorrowChart timePeriod={timePeriod} />
      </Col>
      <Col md={6} className="mb-4">
        <SupplyBorrowAPRChart timePeriod={timePeriod} />
      </Col>
    </Row>
  );
}

export default withErrorBoundary(MarketsSection);
