import React, { useState } from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { parseUTCDateTimestamp } from "../../../utils/datetime.js";

function WalletActivityTable(props) {
  const { address } = props;
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-timestamp");

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `wallets/${address}/events/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results, base_underlying_symbol: baseSymbol } = data;

  if (!results || (results && results.length === 0)) {
    return <div className="text-center gray">no activity</div>;
  }

  return (
    <RemoteTable
      loading={isPreviousData}
      hover={false}
      keyField="id"
      data={results}
      defaultSorted={[
        {
          dataField: "timestamp",
          order: "desc",
        },
      ]}
      columns={[
        {
          dataField: "",
          text: "",
          sort: false,
          formatter: (cell, row) => (
            <CryptoIcon name={row.symbol} size="2em" id={cell} />
          ),
          classes: "table-icon-cell",
        },
        {
          dataField: "symbol",
          text: "",
          headerAlign: "left",
          align: "left",
        },
        {
          dataField: "event_type",
          text: "Event",
          sort: true,
          headerAlign: "left",
          align: "left",
        },
        {
          dataField: "amount_base",
          text: "amount",
          sort: true,
          formatter: (cell, row) => (
            <>
              <ValueChange value={cell} decimals={2} suffix={baseSymbol} compact />
              <br />
              <ValueChange
                className="text-small"
                value={row.amount}
                decimals={2}
                suffix={row.symbol}
                compact
                small
              />
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "timestamp",
          text: "Date",
          sort: true,
          formatter: (cell, row) => (
            <>
              <DateTimeAgo dateTime={parseUTCDateTimestamp(cell)} inDays />
              <br />
              <span className="gray text-small">
                {row.block_number}
                <ExternalLink
                  href={`https://etherscan.io/tx/${row.tx_hash}`}
                  className="ms-2 gray"
                />
              </span>
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
      ]}
      page={page}
      pageSize={pageSize}
      totalPageSize={data.count}
      onPageChange={setPage}
      onSortChange={setOrder}
    />
  );
}

export default withErrorBoundary(WalletActivityTable);
