import React, { useState } from "react";
import classnames from "classnames";
import makeBlockie from "ethereum-blockies-base64";
import { useParams } from "react-router-dom";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import Loader from "../../components/Loader/Loader.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Address from "../../components/Address/Address.js";
import Title from "../../components/Title/Title.js";
import { useQueryParams, useFetch, usePageTitle } from "../../hooks";
import { parseUTCDateTime } from "../../utils/datetime.js";
import { withErrorBoundary } from "../../hoc.js";
import { shorten } from "../../utils/address.js";
import styles from "./CollateralBuyer.module.scss";

function CollateralBuyer(props) {
  const { address } = useParams();
  usePageTitle(shorten(address));

  const queryParams = useQueryParams();
  const queryDaysAgo = queryParams.get("daysAgo") || 30;
  const [daysAgo, setDaysAgo] = useState(queryDaysAgo);
  const pageSize = 25;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);

  const timeOptions = [
    { key: 7, value: "7 D" },
    { key: 30, value: "30 D" },
    { key: 90, value: "90 d" },
    { key: 365, value: "1 Y" },
  ];

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `collateral-buyers/${address}/`,
    { p: page, p_size: pageSize, order, days_ago: daysAgo },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results, base_underlying_symbol: baseSymbol, stats } = data;

  const statsCard = [
    {
      title: "Collateral buys",
      bigValue: (
        <>
          <Value value={stats.count} decimals={0} className="me-2" />
          <ValueChange value={stats.change.count} decimals={0} compact />
        </>
      ),
    },
    {
      title: "collateral sold",
      bigValue: (
        <>
          <Value
            value={stats.total_amount_base}
            decimals={2}
            suffix={baseSymbol}
            className="me-2"
            compact
          />
          <ValueChange
            value={stats.change.total_amount_base}
            decimals={2}
            suffix={baseSymbol}
            compact
          />
        </>
      ),
    },
  ];

  const blockie = makeBlockie(address);

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <img
          className={classnames(styles.roundedCircle, styles.walletLogo, "me-3")}
          src={blockie}
          alt={address}
        />
        <div>
          <h1 className="h2 m-0 p-0">
            <span className="gray">Collateral buyer:</span>{" "}
            <Address value={address} short />
          </h1>
          <div className="d-flex align-items-center">
            <Address value={address} className="text-small gray me-2" />

            <ExternalLink href={`https://etherscan.io/address/${address}`}>
              <CryptoIcon name="etherscan" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <TimeSwitch
        className="justify-content-center mb-5"
        activeOption={daysAgo}
        onChange={setDaysAgo}
        options={timeOptions}
      />

      <StatsBar stats={statsCard} className="mb-5" />

      <Title tag="h2">Collateral buys</Title>

      {results && results.length > 0 ? (
        <RemoteTable
          hover={false}
          loading={isPreviousData}
          keyField="id"
          data={results}
          columns={[
            {
              dataField: "underlying_symbol",
              text: "Collateral amount",
              formatter: (cell, row) => (
                <>
                  <CryptoIcon name={cell} size="2rem" className="me-2" />
                  <Value value={row.amount} decimals={2} compact />
                </>
              ),
            },
            {
              dataField: "amount_base",
              text: "Amount Base",
              headerAlign: "right",
              align: "right",
              sort: true,
              formatter: (cell, row) => (
                <Value value={cell} decimals={2} suffix={baseSymbol} compact />
              ),
            },
            {
              dataField: "datetime",
              text: "Date",
              sort: true,
              formatter: (cell, row) => (
                <>
                  <DateTimeAgo dateTime={parseUTCDateTime(cell)} />
                  <br />
                  <span className="text-small gray">
                    {row.block_number}
                    <ExternalLink
                      href={`https://etherscan.io/tx/${row.tx_hash}`}
                      className="ms-2 gray"
                    />
                  </span>
                </>
              ),
              headerAlign: "right",
              align: "right",
            },
          ]}
          page={page}
          pageSize={pageSize}
          totalPageSize={data.count}
          onSortChange={setOrder}
          onPageChange={setPage}
        />
      ) : (
        <div className="h4 text-center mb-5">
          No liquidations in last {daysAgo} days.
        </div>
      )}
    </>
  );
}

export default withErrorBoundary(CollateralBuyer);
