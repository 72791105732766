import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeBlockie from "ethereum-blockies-base64";
import queryString from "query-string";
import React, { useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useNavigate } from "react-router-dom";
import Address from "../../components/Address/Address.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import Loader from "../../components/Loader/Loader.js";
import RangeSlider from "../../components/RangeSlider/RangeSlider.js";
import SearchInput from "../../components/SearchInput/SearchInput.js";
import Switch from "../../components/Switch/Switch.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import Value from "../../components/Value/Value.js";
import { withErrorBoundary } from "../../hoc.js";
import {
  useFetch,
  usePageTitle,
  useQueryParams,
  useSmartNavigate,
} from "../../hooks.js";
import { parseUTCDateTime } from "../../utils/datetime.js";
import { compact, formatToDecimals } from "../../utils/number.js";
import { getAllQueryParams } from "../../utils/url.js";

function Wallets(props) {
  usePageTitle("Wallets");

  const smartNavigate = useSmartNavigate();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const qParams = getAllQueryParams(queryParams);
  const pageSize = 25;
  const page = parseInt(queryParams.get("page")) || 1;
  const searchText = queryParams.get("search");
  const empty = queryParams.get("empty");
  const onlyBorrow = queryParams.get("onlyBorrow");

  const [healtRateValue, setHealtRateValue] = useState(
    [queryParams.get("minHealthRate"), queryParams.get("maxHealthRate")] || [null, null]
  );
  const [baseValue, setBaseValue] = useState(
    [queryParams.get("minBase"), queryParams.get("maxBase")] || [null, null]
  );

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    "wallets/",
    {
      p: page,
      p_size: pageSize,
      order: queryParams.get("order") || "base",
      search: searchText,
      min_health_rate: healtRateValue[0],
      max_health_rate: healtRateValue[1],
      min_base: baseValue[0],
      max_base: baseValue[1],
      empty: empty,
      only_borrow: onlyBorrow,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const {
    base_underlying_symbol: baseSymbol,
    min_health_rate: minHealthRate,
    max_health_rate: maxHealthRate,
    min_base: minBase,
    max_base: maxBase,
    total_collateral: totalCollateral,
    total_base: totalBase,
    results,
  } = data;

  const onRowClick = (e, row) => {
    smartNavigate(e, `wallets/${row.address}/`);
  };

  const onHealthRateSliderChange = (value) => {
    setHealtRateValue(value);

    const newParams = {
      ...qParams,
      minHealthRate: value[0],
      maxHealthRate: value[1],
      page: 1,
    };
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    }
  };

  const onBaseSliderChange = (value) => {
    setBaseValue(value);

    const newParams = {
      ...qParams,
      minBase: value[0],
      maxBase: value[1],
      page: 1,
    };
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    }
  };

  const onCheckboxChange = (param, value) => {
    const newParams = { ...qParams, page: null, [param]: value || null };
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    } else {
      navigate("./");
    }
  };

  return (
    <>
      <h1 className="mb-4 h2 text-center">Wallets</h1>
      <ToolkitProvider
        bootstrap4
        search
        keyField="address"
        data={results}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (_, row) => {
              const blockie = makeBlockie(row.address);
              return <img src={blockie} alt={row.address} />;
            },
            classes: "blockie",
            footer: () => null,
          },
          {
            dataField: "address",
            text: "Address",
            formatter: (cell) => <Address value={cell} short />,
            footer: () => null,
          },
          {
            dataField: "collateral_symbols",
            text: "Collateral Assets",
            formatter: (cell) => (
              <>
                {cell
                  ? cell.map((symbol) => (
                      <CryptoIcon
                        name={symbol}
                        key={symbol}
                        className="me-1"
                        size="1.5rem"
                      />
                    ))
                  : null}
              </>
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "collateral",
            text: "Collateral",
            sort: true,
            formatter: (cell, row) => (
              <>
                {cell ? (
                  <Value value={cell} decimals={2} suffix={baseSymbol} compact />
                ) : (
                  "-"
                )}
              </>
            ),
            footer: (columnData) => (
              <Value value={totalCollateral} decimals={2} suffix={baseSymbol} compact />
            ),
            footerAlign: "right",
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "base",
            text: baseSymbol,
            sort: true,
            formatter: (cell, row) => (
              <>
                {cell ? (
                  <Value value={cell} decimals={2} suffix={baseSymbol} compact />
                ) : (
                  "-"
                )}
              </>
            ),
            footer: (columnData) => (
              <Value value={totalBase} decimals={2} suffix={baseSymbol} compact />
            ),
            footerAlign: "right",
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "drop",
            text: "drop",
            sort: true,
            formatter: (cell, row) => (
              <>{cell ? <Value value={cell} decimals={0} suffix="%" /> : "-"}</>
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "health_rate",
            text: "Health rate",
            sort: true,
            formatter: (cell, row) => (
              <>
                {cell ? (
                  <Value value={cell} decimals={2} />
                ) : (
                  <FontAwesomeIcon icon={faInfinity} />
                )}
              </>
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "last_activity",
            text: "latest activity",
            sort: true,
            formatter: (cell, row) => (
              <DateTimeAgo dateTime={parseUTCDateTime(cell)} inDays />
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },
        ]}
      >
        {(props) => (
          <div>
            <div className="react-bootstrap-table-search d-flex align-items-center flex-wrap">
              <RangeSlider
                defaultValue={[
                  healtRateValue[0] || minHealthRate,
                  healtRateValue[1] || maxHealthRate,
                ]}
                step={0.01}
                min={minHealthRate}
                max={maxHealthRate}
                formatter={(value) => {
                  return formatToDecimals(value, 2);
                }}
                title="HEALTH RATE"
                onChange={onHealthRateSliderChange}
                className="flex-grow-1 me-5 mb-3"
              />
              <RangeSlider
                defaultValue={[baseValue[0] || minBase, baseValue[1] || maxBase]}
                min={minBase}
                max={maxBase}
                formatter={(value) => {
                  return compact(value);
                }}
                title={baseSymbol + "  AMOUNT"}
                onChange={onBaseSliderChange}
                className="flex-grow-1 me-5 mb-3"
              />
              <Switch
                label="hide empty wallets"
                className="me-5 mb-3"
                style={{ marginTop: "15px" }}
                checked={empty === "1"}
                onCheckedChange={(checked) => onCheckboxChange("empty", checked)}
              />
              <Switch
                label="only borrow"
                className="me-5 mb-3"
                style={{ marginTop: "15px" }}
                checked={onlyBorrow === "1"}
                onCheckedChange={(checked) => onCheckboxChange("onlyBorrow", checked)}
              />
              <div>
                <SearchInput
                  placeholder="Search by address..."
                  initialSearchText={searchText}
                  {...props.searchProps}
                />
              </div>
            </div>

            <RemoteTable
              {...props.baseProps}
              loading={isPreviousData}
              onRowClick={onRowClick}
              page={page}
              pageSize={pageSize}
              totalPageSize={data.count}
              defaultSorted={[
                {
                  dataField: "base",
                  order: "asc",
                },
              ]}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}

export default withErrorBoundary(Wallets);
