import React, { useState } from "react";
import Card from "../../../components/Card/Card.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function MarketsChartLine(props) {
  const { timePeriod, symbol } = props;
  const [graphCurrency, setGraphCurrency] = useState("base");

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/historic-details/`,
    {
      days_ago: timePeriod < 7 ? 7 : timePeriod,
    }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const selectedCurrency = graphCurrency === "base" ? symbol : "USD";

  const currencyOptions = [
    { key: "base", value: <CryptoIcon name={symbol} /> },
    { key: "USD", value: "$" },
  ];

  const seriesData = [];
  data.forEach((row) => {
    seriesData.push({
      x: row.datetime,
      y: graphCurrency === "base" ? row.total_supply : row.total_supply_usd,
    });
  });

  const usdSeries = [
    {
      data: seriesData,
      label: selectedCurrency,
      symbol: selectedCurrency,
    },
  ];

  let prefix = "";
  if (graphCurrency === "USD") {
    prefix = "$";
  }

  const usdOptions = {
    interaction: {
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => prefix + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
        },
      },
    },
  };

  return (
    <Card title="Total collateral supply" minHeight fullHeight>
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <Graph series={usdSeries} options={usdOptions} />
    </Card>
  );
}

export default withErrorBoundary(MarketsChartLine);
