import _ from "lodash";
import React, { useState } from "react";
import Card from "../../../components/Card/Card.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function SupplyHistoryChart(props) {
  const { timePeriod } = props;
  const [graphCurrency, setGraphCurrency] = useState("base");

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/history/`,
    {
      days_ago: timePeriod,
    }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { base_underlying_symbol: baseSymbol, results } = data;

  const currencyOptions = [
    { key: "base", value: <CryptoIcon name={baseSymbol} /> },
    { key: "USD", value: "$" },
  ];

  const grouped = _.groupBy(results, "underlying_symbol");

  const series = [];
  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["datetime"],
        y: graphCurrency === "base" ? row.total_supply_base : row.total_supply_usd,
      })),
    };
    series.push(item);
  });

  let prefix = "";
  if (graphCurrency === "USD") {
    prefix = "$";
  }

  const options = {
    orderSeries: true,
    interaction: {
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => prefix + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem);
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            return `Total: ${prefix}` + compact(total, 2, true);
          },
        },
      },
    },
  };

  return (
    <Card title="Total collateral supply" minHeight fullHeight>
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <Graph series={series} options={options} />
    </Card>
  );
}

export default withErrorBoundary(SupplyHistoryChart);
