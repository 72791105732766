import React, { useState } from "react";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Loader from "../../components/Loader/Loader.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../components/Title/Title.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import BaseAdditionalInfo from "./components/BaseAdditionalInfo.js";
import BaseInfo from "./components/BaseInfo.js";
import CollateralSection from "./components/CollateralSection.js";
import MarketsSection from "./components/MarketsSection.js";
import MarketsTable from "./components/MarketsTable.js";

function Homepage(props) {
  usePageTitle("Compound");

  const [timePeriod, setTimePeriod] = useState(30);
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch("base/", {
    days_ago: timePeriod,
  });

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const options = [
    { key: 1, value: "1 D" },
    { key: 7, value: "7 D" },
    { key: 30, value: "30 D" },
    { key: 90, value: "90 D" },
    { key: 365, value: "1 Y" },
  ];

  const { comet_proxy: cometProxy, underlying_symbol: baseSymbol } = data;

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <CryptoIcon className="me-3" name={baseSymbol} size="3.5rem" />
        <div>
          <h1 className="h2 m-0 p-0">{baseSymbol === "WETH" ? "ETH" : baseSymbol}</h1>

          <div className="d-flex align-items-center">
            <ExternalLink href={`https://etherscan.io/address/${cometProxy}`}>
              <CryptoIcon name="etherscan" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center mb-5">
        <TimeSwitch
          activeOption={timePeriod}
          onChange={setTimePeriod}
          options={options}
          basic
        />
      </div>

      <BaseInfo data={data} className="mb-5" />

      <MarketsSection timePeriod={timePeriod} className="mb-4" />

      <BaseAdditionalInfo data={data} className="mb-5" />

      <Title tag="h2">Collateral Assets</Title>
      <CollateralSection timePeriod={timePeriod} className="mb-4" />

      <MarketsTable timePeriod={timePeriod} />
    </>
  );
}

export default withErrorBoundary(Homepage);
