import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import Card from "../../../components/Card/Card.js";
import { withErrorBoundary } from "../../../hoc.js";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import {
  barGraphSeriesCountLimiter,
  tooltipLabelNumberWithPercent,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function LiquidationsCollateralChart(props) {
  const { data, timePeriod } = props;

  const { series } = barGraphSeriesCountLimiter(data, "key", "value", 7, true);
  const backgroundColor = series[0]["data"].map((row) => {
    return SYMBOLS_PALETTE[row["x"]] || "#0e1726";
  });

  const newSeries = [
    {
      data: series[0]["data"],
      backgroundColor,
      borderColor: backgroundColor,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return tooltipLabelNumberWithPercent(tooltipItem, "$");
          },
        },
      },
    },
  };

  return (
    <Card title={`Collateral seized in last ${timePeriod} days`} minHeight>
      <Graph series={newSeries} options={options} type="bar" />
    </Card>
  );
}

export default withErrorBoundary(LiquidationsCollateralChart);
