import React from "react";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";

function BaseInfo(props) {
  const { data, ...rest } = props;
  const { change } = data;
  const stats = [
    {
      title: "total earning",
      bigValue: (
        <>
          <Value
            value={data.total_supply}
            decimals={2}
            className="me-2"
            suffix={data.underlying_symbol}
            compact
            big
          />
          <ValueChange
            value={data.total_supply - change.total_supply}
            tooltipValue={data.total_supply}
            decimals={2}
            compact
            big
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.total_supply_usd}
            decimals={2}
            prefix="$"
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.total_supply_usd - change.total_supply_usd}
            decimals={2}
            prefix="$"
            tooltipValue={change.total_supply_usd}
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "total borrowing",
      bigValue: (
        <>
          <Value
            value={data.total_borrow}
            suffix={data.underlying_symbol}
            decimals={2}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={data.total_borrow - change.total_borrow}
            decimals={2}
            tooltipValue={change.total_borrow}
            compact
            hideIfZero
            big
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.total_borrow_usd}
            decimals={2}
            prefix="$"
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.total_borrow_usd - change.total_borrow_usd}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
            tooltipValue={change.total_borrow_usd}
          />
        </>
      ),
    },
    {
      title: "total value locked",
      bigValue: (
        <>
          <Value
            value={data.total_supply - data.total_borrow}
            decimals={2}
            suffix={data.underlying_symbol}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={
              data.total_supply -
              data.total_borrow -
              (change.total_supply - change.total_borrow)
            }
            decimals={2}
            tooltipValue={change.total_supply - change.total_borrow}
            compact
            hideIfZero
            big
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.total_supply_usd - data.total_borrow_usd}
            decimals={2}
            prefix="$"
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={
              data.total_supply_usd -
              data.total_borrow_usd -
              (change.total_supply_usd - change.total_borrow_usd)
            }
            decimals={2}
            prefix="$"
            compact
            hideIfZero
            tooltipValue={change.total_supply_usd - change.total_borrow_usd}
          />
        </>
      ),
    },
    {
      title: "oracle price",
      bigValue: (
        <>
          <Value
            value={data.underlying_price}
            decimals={data.underlying_price < 2 ? 4 : 2}
            suffix={data.base_underlying_symbol}
            className="me-2"
            prefix="$"
            compact
            big
          />
          <ValueChange
            value={data.underlying_price - change.underlying_price}
            decimals={data.underlying_price < 2 ? 4 : 2}
            tooltipValue={change.underlying_price}
            suffix={data.base_underlying_symbol}
            prefix="$"
            compact
            hideIfZero
            big
          />
        </>
      ),
    },
  ];

  return <StatsBar stats={stats} {...rest} />;
}

export default withErrorBoundary(BaseInfo);
