import React from "react";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";

function InfoCard(props) {
  const { data, ...rest } = props;
  if (!data) {
    return null;
  }

  const totalStats = [
    {
      title: "Collateral factor",
      normalValue: (
        <Value value={data.collateral_factor * 100} decimals={0} suffix="%" />
      ),
    },
    {
      title: "Liquidation factor",
      normalValue: (
        <Value value={data.liquidation_threshold * 100} decimals={0} suffix="%" />
      ),
    },
    {
      title: "Liqudation penalty",
      normalValue: (
        <Value value={(1 - data.liquidation_penalty) * 100} decimals={0} suffix="%" />
      ),
    },
  ];
  return <StatsBar stats={totalStats} {...rest} />;
}

export default withErrorBoundary(InfoCard);
