import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";

function WalletPositionsBase(props) {
  const { data } = props;

  let title;
  if (data.length > 0) {
    if (data[0]["balance"] > 0) {
      title = "Earning";
    } else {
      title = "Borrowing";
    }
  }

  const columns = [
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <CryptoIcon name={row.underlying_symbol} size="2em" id={cell} />
      ),
      classes: "table-icon-cell",
    },
    {
      dataField: "underlying_symbol",
      text: "",
      formatter: (cell, row) => (
        <>
          {cell}
          <div className="lh-sm">
            <Value value={row.price} decimals={2} prefix="$" className="gray" small />
          </div>
        </>
      ),
    },
    {
      dataField: "balance",
      text: "",
      formatter: (cell, row) => (
        <>
          <Value value={cell} decimals={2} suffix={row.underlying_symbol} compact />
          <div className="lh-sm">
            <Value
              className="gray"
              value={row.balance_usd}
              decimals={2}
              prefix="$"
              compact
              small
            />
          </div>
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <>
      <h3 className="text-center subtitle mb-2">{title}</h3>
      {data.length === 0 ? (
        <div className="text-center gray"></div>
      ) : (
        <BootstrapTable
          keyField="underlying_symbol"
          bootstrap4
          bordered={false}
          hover={false}
          data={data}
          defaultSorted={[
            {
              dataField: "supply_usd",
              order: "desc",
            },
          ]}
          columns={columns}
        />
      )}
    </>
  );
}

export default withErrorBoundary(WalletPositionsBase);
