import React from "react";
import classnames from "classnames";
import makeBlockie from "ethereum-blockies-base64";
import { useParams } from "react-router-dom";
import Address from "../../components/Address/Address.js";
import Loader from "../../components/Loader/Loader.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import Title from "../../components/Title/Title.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import { shorten } from "../../utils/address.js";
import WalletActivityTable from "./components/WalletActivityTable.js";
import WalletInfo from "./components/WalletInfo.js";
import WalletPositions from "./components/WalletPositions.js";
import WalletAdditionalInfo from "./components/WalletAdditionalInfo.js";
import styles from "./Wallet.module.scss";

function Wallet(props) {
  const { address } = useParams();
  usePageTitle(shorten(address));

  const { data, isLoading, isError, ErrorFallbackComponent, error } = useFetch(
    `wallets/${address}/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    // if endponit returns 404, still show the page but with no values
    if (error.response.status !== 404) {
      return <ErrorFallbackComponent />;
    }
  }

  const blockie = makeBlockie(address);

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <img
          className={classnames(styles.roundedCircle, styles.walletLogo, "me-3")}
          src={blockie}
          alt={address}
        />
        <div>
          <h1 className="h2 m-0 p-0">
            <Address value={address} short />
            <CopyToClipboard text={address} className="ms-2 small" />
          </h1>
          <div className="d-flex align-items-center">
            <ExternalLink
              href={`https://etherscan.io/address/${address}`}
              className="me-2"
            >
              <CryptoIcon name="etherscan" />
            </ExternalLink>
            <ExternalLink
              href={`https://debank.com/profile/${address}`}
              className="me-2"
            >
              <CryptoIcon name="debank" />
            </ExternalLink>
            <ExternalLink href={`https://zapper.fi/account/${address}`}>
              <CryptoIcon name="zapper" />
            </ExternalLink>
          </div>
        </div>
      </div>
      <WalletInfo data={data} className="mb-5" />
      <WalletPositions address={address} />

      <WalletAdditionalInfo data={data} className="mb-5" />

      <Title tag="h2">Activity</Title>
      <WalletActivityTable address={address} />
    </>
  );
}

export default withErrorBoundary(Wallet);
