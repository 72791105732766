import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Address from "../../components/Address/Address.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Loader from "../../components/Loader/Loader.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import { parseUTCDateTimestamp } from "../../utils/datetime.js";
import { smartLocationPrefix } from "../../utils/url.js";
import { smartEtherscanUrl } from "../../utils/url.js";
function Activity(props) {
  usePageTitle("Activity");
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-timestamp");
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const etherscanUrl = smartEtherscanUrl(location);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `base/activity/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results, base_underlying_symbol: baseSymbol } = data;

  return (
    <>
      <h1 className="mb-4 h2 text-center">
        Activity for {baseSymbol === "WETH" ? "ETH" : baseSymbol}
      </h1>
      <RemoteTable
        loading={isPreviousData}
        hover={false}
        keyField="id"
        data={results}
        defaultSorted={[
          {
            dataField: "timestamp",
            order: "desc",
          },
        ]}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (cell, row) => (
              <CryptoIcon name={row.underlying_symbol} size="2em" id={cell} />
            ),
            classes: "table-icon-cell",
          },
          {
            dataField: "symbol",
            text: "",
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "wallet_address",
            text: "Wallet",
            formatter: (cell) => (
              <Link to={`${prefix}wallets/${cell}/`} key={cell}>
                <Address value={cell} short />
              </Link>
            ),
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "event_type",
            text: "Event",
            sort: true,
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "amount_usd",
            text: "$ amount",
            sort: true,
            formatter: (cell, row) => (
              <>
                <ValueChange
                  value={row.amount}
                  decimals={2}
                  suffix={row.underlying_symbol}
                  compact
                />
                <br />
                <ValueChange
                  className="text-small"
                  value={cell}
                  decimals={2}
                  prefix="$"
                  compact
                  small
                />
              </>
            ),
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "timestamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => (
              <>
                <DateTimeAgo dateTime={parseUTCDateTimestamp(cell)} />
                <br />
                <span className="gray text-small">
                  {row.block_number}
                  <ExternalLink
                    href={`${etherscanUrl}tx/${row.tx_hash}`}
                    className="ms-2 gray"
                  />
                </span>
              </>
            ),
            headerAlign: "right",
            align: "right",
          },
        ]}
        page={page}
        pageSize={pageSize}
        totalPageSize={data.count}
        onPageChange={setPage}
        onSortChange={setOrder}
      />
    </>
  );
}

export default withErrorBoundary(Activity);
