import _ from "lodash";
import React from "react";
import Card from "../../../components/Card/Card.js";
import Graph from "../../../components/Graph/Graph.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
function TotalAtRiskChart(props) {
  const { chartType } = props;

  const { data, isLoading, isError, ErrorFallbackComponent } =
    useFetch("markets/at-risk/");
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  let y;
  if (chartType === "bar") {
    y = "amount_usd";
  } else {
    y = "total_amount_usd";
  }

  const { results } = data;

  const grouped = _.groupBy(results, "underlying_symbol");
  const series = [];
  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["drop"],
        y: row[y],
      })),
    };
    series.push(item);
  });

  const options = {
    orderSeries: true,
    fill: true,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        stacked: true,
        type: "linear",
        ticks: {
          callback: (value) => `-${value}%`,
        },
        title: {
          display: true,
          text: "price drop",
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
        title: {
          display: true,
          text: `collateral USD amount at risk`,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `At ${tooltipItems[0].parsed.x}% markets price drop`;
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            return "Total: $" + compact(total, 2, true);
          },
        },
      },
    },
  };

  return (
    <Card title="Collateral at risk" minHeight fullHeight>
      <Graph series={series} options={options} type={chartType} />
    </Card>
  );
}

export default withErrorBoundary(TotalAtRiskChart);
