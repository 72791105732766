import React from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";

function BaseAdditionalInfo(props) {
  const { data, ...rest } = props;

  const { change } = data;

  const stats = [
    {
      title: "earn APR",
      bigValue: (
        <>
          <Value
            value={data.supply_rate * 100 + data.supply_reward_rate * 100}
            decimals={2}
            suffix="%"
            className="me-2"
          />
          <ValueChange
            value={
              data.supply_rate * 100 +
              data.supply_reward_rate * 100 -
              (change.supply_rate * 100 + change.supply_reward_rate * 100)
            }
            decimals={2}
            suffix="%"
            tooltipValue={change.supply_rate * 100 + change.supply_reward_rate * 100}
            compact
            hideIfZero
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.supply_rate * 100}
            decimals={2}
            suffix="%"
            className="me-1 gray"
          />
          <CryptoIcon name={data.underlying_symbol} size="1rem" className="me-2" />
          <Value
            value={data.supply_reward_rate * 100}
            decimals={2}
            prefix="+"
            suffix="%"
            className="me-1 gray"
          />
          <CryptoIcon name={"COMP"} size="1rem" />
        </>
      ),
    },
    {
      title: "borrow APR",
      bigValue: (
        <>
          <Value
            value={data.borrow_rate * 100 - data.borrow_reward_rate * 100}
            decimals={2}
            suffix="%"
            className="me-2"
          />
          <ValueChange
            value={
              data.borrow_rate * 100 -
              data.borrow_reward_rate * 100 -
              (change.borrow_rate * 100 - change.borrow_reward_rate * 100)
            }
            decimals={2}
            suffix="%"
            tooltipValue={change.borrow_rate * 100 - change.borrow_reward_rate * 100}
            compact
            hideIfZero
            reverse
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.borrow_rate * 100}
            decimals={2}
            suffix="%"
            className="me-1 gray"
          />
          <CryptoIcon name={data.underlying_symbol} size="1rem" className="me-2" />
          <Value
            value={data.borrow_reward_rate * 100}
            decimals={2}
            suffix="%"
            prefix="-"
            className="me-1 gray"
          />
          <CryptoIcon name={"COMP"} size="1rem" />
        </>
      ),
    },
    {
      title: "utilization",
      bigValue: (
        <>
          <Value
            value={(data.total_borrow_usd / data.total_supply_usd) * 100}
            decimals={2}
            suffix="%"
            className="me-2"
          />
          <ValueChange
            value={
              (data.total_borrow_usd / data.total_supply_usd) * 100 -
              (change.total_borrow_usd / change.total_supply_usd) * 100
            }
            decimals={2}
            suffix="%"
            tooltipValue={(change.total_borrow_usd / change.total_supply_usd) * 100}
            hideIfZero
            reverse
          />
        </>
      ),
    },
    {
      title: "total collateral",
      bigValue: (
        <>
          <Value
            value={data.total_collateral_base}
            decimals={2}
            className="me-2"
            suffix={data.underlying_symbol}
            compact
            big
          />
          <ValueChange
            value={data.total_collateral_base - change.total_collateral_base}
            tooltipValue={data.total_collateral_base}
            decimals={2}
            compact
            big
          />
        </>
      ),
      normalValue: (
        <>
          <Value
            value={data.total_collateral_usd}
            decimals={2}
            prefix="$"
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.total_collateral_usd - change.total_collateral_usd}
            decimals={2}
            prefix="$"
            tooltipValue={change.total_collateral_usd}
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  return <StatsBar stats={stats} {...rest} />;
}

export default withErrorBoundary(BaseAdditionalInfo);
