import classnames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import { withErrorBoundary } from "../../../hoc.js";
import HistoricDetails from "./HistoricDetails.js";
import UtilizationChart from "./UtilizationChart.js";

function HistoricChartsSection(props) {
  const { timePeriod, underlyingSymbol, className, ...rest } = props;

  return (
    <Row className={classnames(className, "justify-content-center")} {...rest}>
      <Col md={6} className="mb-4">
        <HistoricDetails symbol={underlyingSymbol} timePeriod={timePeriod} />
      </Col>
      <Col md={6} className="mb-4">
        <UtilizationChart symbol={underlyingSymbol} timePeriod={timePeriod} />
      </Col>
    </Row>
  );
}

export default withErrorBoundary(HistoricChartsSection);
