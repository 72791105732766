import React, { useState } from "react";
import Card from "../../../components/Card/Card.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function SupplyBorrowAPRChart(props) {
  const { timePeriod } = props;
  const [graphSwitchOption, setGraphSwitchOption] = useState("without");
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(`base/stats/`, {
    days_ago: timePeriod,
  });

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const graphSwitchOptions = [
    { key: "without", value: "w/o" },
    { key: "with", value: "w/" },
    { key: "only", value: "only" },
  ];

  const data1 = [];
  const data2 = [];

  data.results.forEach((row) => {
    if (graphSwitchOption === "without") {
      data1.push({
        x: row.datetime,
        y: row.supply_rate * 100,
      });
      data2.push({
        x: row.datetime,
        y: row.borrow_rate * 100,
      });
    } else if (graphSwitchOption === "with") {
      data1.push({
        x: row.datetime,
        y: (row.supply_rate + row.supply_reward_rate) * 100,
      });
      data2.push({
        x: row.datetime,
        y: (row.borrow_rate - row.borrow_reward_rate) * 100,
      });
    } else if (graphSwitchOption === "only") {
      data1.push({
        x: row.datetime,
        y: row.supply_reward_rate * 100,
      });
      data2.push({
        x: row.datetime,
        y: row.borrow_reward_rate * 100,
      });
    }
  });

  let label1 = "";
  let label2 = "";

  if (graphSwitchOption === "without") {
    label1 = "earn APR w/o rewards";
    label2 = "borrow APR w/o rewards";
  } else if (graphSwitchOption === "with") {
    label1 = "earn APR w/ rewards";
    label2 = "borrow APR w/ rewards";
  } else if (graphSwitchOption === "only") {
    label1 = "earn rewards";
    label2 = "borrow rewards";
  }

  const series = [
    {
      label: label1,
      data: data1,
    },
    {
      label: label2,
      data: data2,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => compact(value, 2, true) + "%",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem) + "%";
          },
        },
      },
    },
  };

  return (
    <Card title="Earn/Borrow APR" minHeight>
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Rewards:"
          options={graphSwitchOptions}
          onChange={(option) => setGraphSwitchOption(option)}
        />
      </div>
      <Graph series={series} options={options} />
    </Card>
  );
}

export default withErrorBoundary(SupplyBorrowAPRChart);
