import React, { useState } from "react";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import MarketsTable from "../homepage/components/MarketsTable.js";
import { withErrorBoundary } from "../../hoc.js";
import { usePageTitle } from "../../hooks";

function Markets(props) {
  usePageTitle("Markets");

  const [timePeriod, setTimePeriod] = useState(30);

  const options = [
    { key: 1, value: "1 D" },
    { key: 7, value: "7 D" },
    { key: 30, value: "30 D" },
    { key: 90, value: "90 D" },
    { key: 365, value: "1 Y" },
  ];

  return (
    <>
      <div className="justify-content-center my-5 text-center">
        <h1 className="h2 m-0 p-0">Markets</h1>
      </div>

      <TimeSwitch
        activeOption={timePeriod}
        onChange={setTimePeriod}
        options={options}
        className="justify-content-center mb-5"
      />

      <MarketsTable timePeriod={timePeriod} />
    </>
  );
}

export default withErrorBoundary(Markets);
