import React from "react";
import Card from "../../../components/Card/Card.js";
import Graph from "../../../components/Graph/Graph.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function UtilizationChart(props) {
  const { timePeriod, symbol } = props;

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/historic-details/`,
    {
      days_ago: timePeriod < 7 ? 7 : timePeriod,
    }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const utilizationData = [];
  const supplyCapData = [];
  data.forEach((row) => {
    utilizationData.push({
      x: row.datetime,
      y: row.utilization * 100,
    });
    supplyCapData.push({
      x: row.datetime,
      y: row.supply_cap,
    });
  });

  const series = [
    {
      data: utilizationData,
      label: "utilization",
      symbol: symbol,
    },
    {
      data: supplyCapData,
      label: "supply cap",
      yAxisID: "y1",
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => compact(value, 2, true) + "%",
        },
        beginAtZero: true,
      },
      y1: {
        type: "linear",
        position: "right",
        ticks: {
          callback: (value) => compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            if (tooltipItem.dataset.yAxisID === "y1") {
              return tooltipLabelNumber(tooltipItem);
            }
            return tooltipLabelNumber(tooltipItem, null, "%");
          },
        },
      },
    },
  };

  return (
    <Card title="Utilization" minHeight fullHeight>
      <Graph series={series} options={options} />
    </Card>
  );
}

export default withErrorBoundary(UtilizationChart);
