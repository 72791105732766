import React, { useState } from "react";
import Card from "../../../components/Card/Card.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function TotalSupplyBorrowChart(props) {
  const { timePeriod } = props;
  const [graphCurrency, setGraphCurrency] = useState("base");

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(`base/stats/`, {
    days_ago: timePeriod,
  });

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { base_underlying_symbol: baseSymbol } = data;

  const currencyOptions = [
    { key: "base", value: <CryptoIcon name={baseSymbol} /> },
    { key: "USD", value: "$" },
  ];

  const data1 = [];
  const data2 = [];

  let prefix = "";
  if (graphCurrency === "USD") {
    prefix = "$";
  }

  data.results.forEach((row) => {
    data1.push({
      x: row.datetime,
      y: graphCurrency === "base" ? row.total_supply : row.total_supply_usd,
    });
    data2.push({
      x: row.datetime,
      y: graphCurrency === "base" ? row.total_borrow : row.total_borrow_usd,
    });
  });

  const series = [
    {
      label: "earn",
      data: data1,
    },
    {
      label: "borrow",
      data: data2,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => prefix + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem);
          },
        },
      },
    },
  };

  return (
    <Card title="Total earn/borrow" minHeight>
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <Graph series={series} options={options} />
    </Card>
  );
}

export default withErrorBoundary(TotalSupplyBorrowChart);
