import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import Address from "../../../components/Address/Address.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { useSmartNavigate } from "../../../hooks.js";

function WalletPositions(props) {
  const { symbol } = props;
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-timestamp");

  const navigate = useSmartNavigate();

  const onRowClick = (e, row) => {
    navigate(e, `wallets/${row.address}/`);
  };

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/wallets/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { base_underlying_symbol: baseSymbol } = data;

  const columns = [
    {
      dataField: "",
      text: "",
      formatter: (_, row) => {
        const blockie = makeBlockie(row.address);
        return <img src={blockie} alt={row.address} />;
      },
      classes: "blockie",
    },
    {
      dataField: "address",
      text: "",
      formatter: (cell) => <Address value={cell} short />,
    },
    {
      dataField: "supply",
      text: "Supply",
      sort: true,
      formatter: (cell, row) => (
        <>
          <Value
            value={row.supply}
            decimals={2}
            suffix={row.underlying_symbol}
            compact
          />
          <br />
          <Value
            value={row.supply_base}
            decimals={2}
            suffix={baseSymbol}
            compact
            className="gray"
          />
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "supply_change_1d_base",
      text: "1 D change",
      sort: true,
      formatter: (cell, row) => (
        <>
          {cell ? (
            <>
              <ValueChange
                value={row.supply_change_1d}
                decimals={2}
                suffix={row.underlying_symbol}
                compact
              />
              <br />
              <ValueChange
                value={cell}
                decimals={2}
                suffix={baseSymbol}
                className="gray"
                compact
                small
              />
            </>
          ) : null}
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "supply_change_7d_base",
      text: "7 D change",
      sort: true,
      formatter: (cell, row) => (
        <>
          {cell ? (
            <>
              <ValueChange
                value={row.supply_change_7d}
                decimals={2}
                suffix={row.underlying_symbol}
                compact
              />
              <br />
              <ValueChange
                value={cell}
                decimals={2}
                suffix={baseSymbol}
                className="gray"
                compact
                small
              />
            </>
          ) : null}
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "supply_change_30d_base",
      text: "30 D change",
      sort: true,
      formatter: (cell, row) => (
        <>
          {cell ? (
            <>
              <ValueChange
                value={row.supply_change_30d}
                decimals={2}
                suffix={row.underlying_symbol}
                compact
              />
              <br />
              <ValueChange
                value={cell}
                decimals={2}
                suffix={baseSymbol}
                className="gray"
                compact
                small
              />
            </>
          ) : null}
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <>
      {data.length === 0 ? (
        <div className="text-center gray">no wallets</div>
      ) : (
        <RemoteTable
          columns={columns}
          loading={isPreviousData}
          onRowClick={onRowClick}
          keyField="address"
          data={data.results}
          defaultSorted={[
            {
              dataField: "timestamp",
              order: "desc",
            },
          ]}
          page={page}
          pageSize={pageSize}
          totalPageSize={data.count}
          onPageChange={setPage}
          onSortChange={setOrder}
        />
      )}
    </>
  );
}

export default withErrorBoundary(WalletPositions);
