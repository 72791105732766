import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Value from "../../../components/Value/Value.js";

function CollateralInfoCell(props) {
  const { cell } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  if (cell.length === 1) {
    const el = cell[0];
    return (
      <div className="d-flex align-items-center">
        <CryptoIcon name={el.underlying_symbol} size="2em" className="me-3" />
        <div>
          <Value value={el.amount} decimals={3} compact suffix={el.underlying_symbol} />
          <br />
          <Value
            value={el.amount_usd}
            decimals={2}
            prefix="$"
            className="text-small gray"
            compact
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="text-nowrap">
          {cell.map((el) => (
            <CryptoIcon
              name={el.underlying_symbol}
              size="2em"
              className="me-2"
              key={uniqueId(`icon-${el.underlying_symbol}`)}
            />
          ))}
        </div>
        <div className="link gray" onClick={toggle}>
          <FontAwesomeIcon
            style={{ fontSize: ".8em" }}
            icon={isOpen ? faChevronUp : faChevronDown}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="mt-3">
          {cell.map((el) => (
            <div key={uniqueId(`icon-${el.underlying_symbol}`)}>
              <Value
                value={el.amount}
                decimals={3}
                suffix={el.underlying_symbol}
                compact
              />
              <span className="gray"> / </span>
              <Value
                value={el.amount_usd}
                decimals={2}
                prefix="$"
                className="text-small gray"
                compact
              />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
}

export default CollateralInfoCell;
