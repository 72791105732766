import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Loader from "../../components/Loader/Loader.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../components/Title/Title.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle, useSmartNavigate } from "../../hooks";
import AtRiskSection from "./components/AtRiskSection.js";
import HistoricChartsSection from "./components/HistoricChartsSection.js";
import InfoCard from "./components/InfoCard.js";
import MarketInfo from "./components/MarketInfo.js";
import WalletPositions from "./components/WalletPositions.js";

function Market(props) {
  const { symbol } = useParams();

  const navigate = useSmartNavigate();
  usePageTitle(symbol);

  const [timePeriod, setTimePeriod] = useState(30);

  const options = [
    { key: 1, value: "1 D" },
    { key: 7, value: "7 D" },
    { key: 30, value: "30 D" },
    { key: 90, value: "90 D" },
    { key: 365, value: "1 Y" },
  ];

  const { data, isLoading, isError, ErrorFallbackComponent, error } = useFetch(
    `markets/${symbol}/`,
    { days_ago: timePeriod }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    // On 404 redirect to homepage
    if (error.response.status === 404) {
      navigate(null, "./");
    } else {
      return <ErrorFallbackComponent />;
    }
  }

  const {
    underlying_symbol: underlyingSymbol,
    name,
    underlying_address: underlyingAddress,
  } = data;

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <CryptoIcon
          name={underlyingSymbol}
          address={underlyingAddress}
          size="3.5rem"
          className="me-3"
        />
        <div>
          <h1 className="h2 m-0 p-0">{name}</h1>
          <div className="text-small gray">
            {underlyingSymbol}
            <ExternalLink
              href={`https://etherscan.io/address/${underlyingAddress}`}
              className="ms-2"
            >
              <CryptoIcon name="etherscan" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <TimeSwitch
        activeOption={timePeriod}
        onChange={setTimePeriod}
        options={options}
        className="justify-content-center mb-5"
      />

      <MarketInfo data={data} className="mb-5" />

      <HistoricChartsSection
        underlyingSymbol={underlyingSymbol}
        timePeriod={timePeriod}
        className="mb-4"
      />
      <Title tag="h2">collateral at risk</Title>
      <AtRiskSection
        underlyingSymbol={underlyingSymbol}
        timePeriod={timePeriod}
        className="mb-4"
      />
      <InfoCard data={data} className="mb-5" />

      <Title tag="h2">Token positions</Title>
      <WalletPositions symbol={underlyingSymbol} />
    </>
  );
}

export default withErrorBoundary(Market);
