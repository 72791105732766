import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Card as StrapCard, CardBody, CardTitle } from "reactstrap";
import slugify from "slugify";
import styles from "./Card.module.scss";

function Card(props) {
  const { className, title, children, fullHeight, id, minHeight, ...rest } = props;
  let anchorId;
  if (id || typeof title === "string") {
    anchorId = slugify(id || title).toLowerCase();
  }
  let minStyleHeight = "";
  if (minHeight === true) {
    minStyleHeight = "320px";
  } else if (typeof minHeight === "string") {
    minStyleHeight = minHeight;
  }

  return (
    <StrapCard
      id={anchorId}
      className={classnames(className, styles.roundBorder, {
        "h-100": fullHeight,
      })}
      {...rest}
    >
      {title ? (
        <CardTitle tag="h6" className={classnames("text-content", styles.title)}>
          {title}
        </CardTitle>
      ) : null}
      <CardBody className={styles.body} style={{ minHeight: minStyleHeight }}>
        {children}
      </CardBody>
    </StrapCard>
  );
}

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  minHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Card.defaultProps = {
  fullHeight: false,
};

export default Card;
