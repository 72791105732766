import React, { useState } from "react";
import Card from "../../../components/Card/Card.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function MarketAtRiskChart(props) {
  const { symbol, chartType } = props;
  const [graphCurrency, setGraphCurrency] = useState("base");

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/at-risk/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const currencyOptions = [
    { key: "base", value: <CryptoIcon name={symbol} /> },
    { key: "USD", value: "$" },
  ];

  const selectedCurrency = graphCurrency === "base" ? symbol : "USD";

  let y;
  if (graphCurrency === "base") {
    if (chartType === "bar") {
      y = "amount";
    } else {
      y = "total_amount";
    }
  } else {
    if (chartType === "bar") {
      y = "amount_usd";
    } else {
      y = "total_amount_usd";
    }
  }

  const seriesData = [];
  data.forEach((row) => {
    seriesData.push({
      x: row.drop,
      y: row[y],
    });
  });

  const usdSeries = [
    {
      data: seriesData,
      label: selectedCurrency,
      symbol: selectedCurrency,
    },
  ];

  let prefix = "";
  if (graphCurrency === "USD") {
    prefix = "$";
  }

  const usdOptions = {
    interaction: {
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        stacked: true,
        type: "linear",
        ticks: {
          callback: (value) => `-${value}%`,
        },
        title: {
          display: true,
          text: "price drop",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => prefix + compact(value, 2, true),
        },
        beginAtZero: true,
        title: {
          display: true,
          text: `collateral amount at risk`,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `At ${tooltipItems[0].parsed.x}% markets price drop`;
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, prefix);
          },
        },
      },
    },
  };

  return (
    <Card minHeight>
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <Graph series={usdSeries} options={usdOptions} type={chartType} />
    </Card>
  );
}

export default withErrorBoundary(MarketAtRiskChart);
