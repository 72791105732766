import classnames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import { withErrorBoundary } from "../../../hoc.js";
import MarketAtRiskChart from "./MarketAtRiskChart.js";

function AtRiskSection(props) {
  const { timePeriod, underlyingSymbol, className, ...rest } = props;

  return (
    <Row className={classnames(className, "justify-content-center")} {...rest}>
      <Col md={6} className="mb-4">
        <MarketAtRiskChart symbol={underlyingSymbol} timePeriod={timePeriod} />
      </Col>
      <Col md={6} className="mb-4">
        <MarketAtRiskChart
          symbol={underlyingSymbol}
          timePeriod={timePeriod}
          chartType="bar"
        />
      </Col>
    </Row>
  );
}

export default withErrorBoundary(AtRiskSection);
