import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import styles from "./WalletInfo.module.scss";

function WalletInfo(props) {
  let { data, ...rest } = props;
  if (!data) {
    data = {};
  }

  const {
    collateral,
    health_rate: healthRate,
    base,
    collateral_usd: collateralUsd,
    base_usd: baseUsd,
    base_underlying_symbol: baseSymbol,
  } = data;

  return (
    <div {...rest}>
      <div className={styles.sectionWrapper}>
        <div className={styles.section}>
          <div className="section-title">Collateral</div>
          <div>
            {collateral ? (
              <Value
                value={collateral || 0}
                decimals={2}
                suffix={baseSymbol}
                iconSize="1.2rem"
                compact
              />
            ) : (
              "-"
            )}
          </div>
          <Value
            value={collateralUsd || 0}
            decimals={2}
            prefix="$"
            iconSize="1.2rem"
            className="gray justify-content-center"
            compact
            small
            hideIfZero
          />
        </div>

        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <div className="section-title">Health Rate</div>
          <div className={styles.sectionMiddleValue}>
            {healthRate ? (
              <Value value={healthRate} decimals={2} compact />
            ) : (
              <FontAwesomeIcon icon={faInfinity} />
            )}
          </div>
        </div>

        <div className={styles.section}>
          <div className="section-title">{base < 0 ? "Borrowing" : "Earning"}</div>
          <div>
            {base ? (
              <Value
                value={base || 0}
                decimals={2}
                suffix={baseSymbol}
                iconSize="1.2rem"
                compact
              />
            ) : (
              "-"
            )}
          </div>
          <Value
            value={baseUsd || 0}
            decimals={2}
            prefix="$"
            iconSize="1.2rem"
            className="gray justify-content-center"
            compact
            small
            hideIfZero
          />
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(WalletInfo);
