import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Loader from "../../components/Loader/Loader.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../components/Title/Title.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks";
import CollateralBuyersTable from "./components/CollateralBuyersTable.js";
import CollateralBuyersTop10Chart from "./components/CollateralBuyersTop10Chart.js";
import LiquidationsChart from "./components/LiquidationsChart.js";
import LiquidationsCollateralChart from "./components/LiquidationsCollateralChart.js";
import LiquidationsTable from "./components/LiquidationsTable.js";
import CollateralBought from "./components/CollateralBought.js";

function Liquidations(props) {
  usePageTitle("Liquidations");
  const [timePeriod, setDaysAgo] = useState(30);

  const timeOptions = [
    { key: 7, value: "7 D" },
    { key: 30, value: "30 D" },
    { key: 90, value: "90 D" },
    { key: 365, value: "1 Y" },
  ];

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "liquidations/stats/",
    { days_ago: timePeriod },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const {
    stats,
    liquidations,
    collateral_sold: collateralSold,
    top10_collateral_buyers: collateralBuyers,
    base_underlying_symbol: baseUnderlyingSymbol,
    collateral_bought: collateralBought,
  } = data;

  const statsData = [
    {
      title: "# of liquidations",
      bigValue: (
        <>
          <Value value={stats.count} decimals={0} className="me-2" />
          <ValueChange value={stats.change.count} decimals={0} compact hideIfZero />
        </>
      ),
    },
    {
      title: "collateral seized",
      bigValue: (
        <>
          <Value
            value={stats.collateral_amount_usd || 0}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={stats.change.collateral_amount_usd || 0}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "debt repaid",
      bigValue: (
        <>
          <Value
            value={stats.debt_amount_usd || 0}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={stats.change.debt_amount_usd || 0}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "liquidation penalty",
      bigValue: (
        <>
          <Value
            value={(stats.collateral_amount_usd || 0) - (stats.debt_amount_usd || 0)}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={
              (stats.change.collateral_amount_usd || 0) -
              (stats.change.debt_amount_usd || 0)
            }
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="mb-4 h2 text-center">Liquidations</h1>

      <TimeSwitch
        className="mb-5 justify-content-center"
        activeOption={timePeriod}
        onChange={setDaysAgo}
        options={timeOptions}
      />

      <StatsBar stats={statsData} className="mb-5" />

      {Object.keys(liquidations).length > 0 ? (
        <>
          <Row>
            <Col md={6} className="mb-5">
              <LiquidationsChart
                data={liquidations}
                daysAgo={timePeriod}
                type="collateral"
                baseUnderlyingSymbol={baseUnderlyingSymbol}
              />
            </Col>
            {/* <Col md={6} className="mb-5">
              <h3 className="text-center subtitle mb-2">Debt repaid</h3>
              <LiquidationsChart
                data={liquidations}
                daysAgo={timePeriod}
                type="debt"
                baseUnderlyingSymbol={baseUnderlyingSymbol}
              />
            </Col> */}
            <Col md={6} className="mb-5">
              <LiquidationsCollateralChart
                data={collateralSold}
                timePeriod={timePeriod}
              />
            </Col>
          </Row>

          <Title tag="h2">Liquidations</Title>
          <LiquidationsTable daysAgo={timePeriod} />

          <Title tag="h2">Collateral Buyers</Title>
          <Row className="justify-content-center">
            <Col md={6} className="mb-5">
              <CollateralBuyersTop10Chart
                data={collateralBuyers}
                baseUnderlyingSymbol={baseUnderlyingSymbol}
                timePeriod={timePeriod}
              />
            </Col>
            <Col md={6} className="mb-5">
              <CollateralBought
                data={collateralBought}
                baseUnderlyingSymbol={baseUnderlyingSymbol}
                timePeriod={timePeriod}
              />
            </Col>
          </Row>
          <CollateralBuyersTable daysAgo={timePeriod} />
        </>
      ) : (
        <div className="h4 text-center mb-5">
          No liquidations in last {timePeriod} days.
        </div>
      )}
    </>
  );
}

export default withErrorBoundary(Liquidations);
