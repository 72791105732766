import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Address from "../../../components/Address/Address.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import { smartLocationPrefix } from "../../../utils/url.js";

function CollateralBuyersTable(props) {
  const pageSize = 10;
  const { daysAgo } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);

  const location = useLocation();
  const prefix = smartLocationPrefix(location);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    "collateral-buyers/",
    {
      p: page,
      p_size: pageSize,
      order,
      days_ago: daysAgo,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results, base_underlying_symbol: baseSymbol } = data;

  return (
    <RemoteTable
      hover={false}
      loading={isPreviousData}
      keyField="id"
      data={results}
      columns={[
        {
          dataField: "",
          text: "",
          formatter: (_, row) => {
            const blockie = makeBlockie(row.wallet_address);
            return <img src={blockie} alt={row.wallet_address} />;
          },
          classes: "blockie",
        },
        {
          dataField: "wallet_address",
          text: "Wallet address",
          formatter: (cell, row) => (
            <Link to={`${prefix}collateral-buyers/${cell}/`} key={cell}>
              <Address value={cell} short />
            </Link>
          ),
        },
        {
          dataField: "underlying_symbol",
          text: "Collateral amount",
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <CryptoIcon name={cell} size="2em" className="me-3" />
              <div>
                <Value value={row.amount} decimals={3} compact suffix={cell} />
                <br />
                <Value
                  value={row.amount_usd}
                  decimals={2}
                  prefix="$"
                  className="text-small gray"
                  compact
                />
              </div>
            </div>
          ),
        },
        {
          dataField: "amount_base",
          text: "Amount Base",
          headerAlign: "right",
          align: "right",
          sort: true,
          formatter: (cell, row) => (
            <Value value={cell} decimals={2} suffix={baseSymbol} compact />
          ),
        },
        {
          dataField: "datetime",
          text: "Date",
          sort: true,
          formatter: (cell, row) => (
            <>
              <DateTimeAgo dateTime={parseUTCDateTime(cell)} />
              <br />
              <span className="text-small gray">
                {row.block_number}
                <ExternalLink
                  href={`https://etherscan.io/tx/${row.tx_hash}`}
                  className="ms-2 gray"
                />
              </span>
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
      ]}
      page={page}
      pageSize={pageSize}
      totalPageSize={data.count}
      onSortChange={setOrder}
      onPageChange={setPage}
    />
  );
}

export default withErrorBoundary(CollateralBuyersTable);
